import { Outlet } from "@remix-run/react";

export default function AuthLayout() {
  return (
    <>
      <div className="flex min-h-dvh w-full flex-col items-center justify-center p-6 md:p-0">
        <Outlet />
      </div>
    </>
  );
}
